import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { useStaticQuery, graphql, Link } from "gatsby";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Head from "../components/head";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Header from "../components/Header_normal";
import Footer from "../components/footer";

const trcell = {
  height: 50,
  color: "#4C4C4C",
  verticalAlign: "text-top",
};

const mapstyle = {
  style:
    "border:0;width:100%;filter:grayscale(100%);-webkit-filter:grayscale(100%)",
};

const Company = () => {
  return (
    <>
      <Header isBlack={true} />
      <Box maxWidth="900px" margin="auto">
        <Head />

        <Box width="95%" margin="auto" mt={5} mb={8}>
          <Box mb={8}>
            <Hidden smDown>
              <Box
                style={{
                  textOrientation: "upright",
                  writingMode: "vertical-rl",
                  marginLeft: "auto",
                }}
              >
                <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                  会社概要
                </WrapTypo>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box
                style={{
                  textOrientation: "upright",
                  writingMode: "vertical-rl",
                  margin: "auto",
                }}
              >
                <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                  会社概要
                </WrapTypo>
              </Box>
            </Hidden>
          </Box>
          <Grid container style={{ height: "90%" }}>
            <Grid item xs={12} md={6}>
              <Box style={{ margin: "auto" }} width="95%" mb={8}>
                <Hidden xsDown>
                  <WrapTypo fs="14px" lh="180%" margin="auto">
                    <table>
                      <tr style={trcell}>
                        <td>名称： 　　　　　</td>
                        <td>株式会社早藤工務店</td>
                      </tr>
                      <tr style={trcell}>
                        <td>所在地 :　　　　</td>
                        <td>〒998-0016 山形県酒田市北千日町4-5</td>
                      </tr>
                      <tr style={trcell}>
                        <td>TEL :　　　　</td>
                        <td>0234-31-7810</td>
                      </tr>
                      <tr style={trcell}>
                        <td>FAX :　　　　</td>
                        <td>0234-31-7811</td>
                      </tr>
                      <tr style={trcell}>
                        <td>代表 :　　　　</td>
                        <td>早藤　亮</td>
                      </tr>
                      <tr style={trcell}>
                        <td>登録 :　　　　</td>
                        <td>
                          建設業許認可登録
                          <br />
                          山形県知事許可（般-4）第700637号 <br />
                          二級建築士設計事務所登録　 <br />
                          山形県知事登録(2706)第1109号 <br />
                          <br />
                          保有資格　二級建築士　第6281号 <br />
                          {`　　　　　`}二級建築士　台9108号
                        </td>
                      </tr>
                    </table>
                  </WrapTypo>
                </Hidden>
                <Hidden smUp>
                  <Box style={{ margin: "auto" }} width="95%" mt={2}>
                    <WrapTypo fs="14px" lh="180%" margin="auto">
                      <table>
                        <tr style={trcell}>
                          <td>名称： 　　　　　</td>
                          <td>株式会社早藤工務店</td>
                        </tr>
                        <tr style={trcell}>
                          <td>所在地 :　　　　</td>
                          <td>〒998-0016 山形県酒田市北千日町4-5</td>
                        </tr>
                        <tr style={trcell}>
                          <td>TEL :　　　　</td>
                          <td>0234-31-7810</td>
                        </tr>
                        <tr style={trcell}>
                          <td>FAX :　　　　</td>
                          <td>0234-31-7811</td>
                        </tr>
                        <tr style={trcell}>
                          <td>代表 :　　　　</td>
                          <td>早藤　亮</td>
                        </tr>
                        <tr style={trcell}>
                          <td>登録 :　　　　</td>
                          <td>
                            建設業許認可登録
                            <br />
                            山形県知事許可(般-29)第700637号 <br />
                            二級建築士設計事務所登録　 <br />
                            山形県知事登録(2706)第1109号 <br />
                            <br />
                            保有資格　二級建築士　第6281号 <br />
                            {`　　　　　`}二級建築士　台9108号
                          </td>
                        </tr>
                      </table>
                    </WrapTypo>
                  </Box>
                </Hidden>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Hidden xsDown>
                <Box mb={1}>
                  <WrapTypo color="#4C4C4C">アクセス</WrapTypo>
                  <WrapTypo
                    fs="14px"
                    lh="180%"
                    margin="auto"
                    lh={2}
                    color="#4C4C4C"
                  >
                    お車でお越しのお客様　酒田中央インターから20分
                    <br />
                    電車・徒歩でお越しのお客様　酒田駅から徒歩20分
                  </WrapTypo>
                </Box>
              </Hidden>
              <Hidden smUp>
                <Box mb={1} mt={8} style={{ margin: "auto" }} width="95%">
                  <WrapTypo color="#4C4C4C">アクセス</WrapTypo>
                  <WrapTypo
                    fs="14px"
                    lh="180%"
                    margin="auto"
                    lh={2}
                    color="#4C4C4C"
                  >
                    お車でお越しのお客様　酒田中央インターから20分
                    <br />
                    電車・徒歩でお越しのお客様　酒田駅から徒歩20分
                  </WrapTypo>
                </Box>
              </Hidden>

              <Box mb={10} style={{ margin: "auto" }} width="95%">
                <Hidden smUp>
                  <div
                    style={{
                      filter: "grayscale(100%)",
                      webKitlow: "grayscale(100%)",
                    }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6874705676437!2d139.8378401153513!3d38.93112027956537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8e9e84f709e427%3A0x2bf60f13973952ba!2zKOagqinml6nol6Tlt6Xli5nlupc!5e0!3m2!1sja!2sjp!4v1619764860494!5m2!1sja!2sjp"
                      style={{ border: "none" }}
                      width="300"
                      height="300"
                    ></iframe>
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div
                    style={{
                      filter: "grayscale(100%)",
                      webKitlow: "grayscale(100%)",
                    }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6874705676437!2d139.8378401153513!3d38.93112027956537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8e9e84f709e427%3A0x2bf60f13973952ba!2zKOagqinml6nol6Tlt6Xli5nlupc!5e0!3m2!1sja!2sjp!4v1619764860494!5m2!1sja!2sjp"
                      width="400"
                      height="450"
                      style={{ border: "none" }}
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </Hidden>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Company;
